// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-style {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.calendar-style > .dotIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.inputs {
  z-index: 10;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px -1px;
  padding: 10px;
  position: absolute;
  top: 30%;
  right: 30%;
  border-radius: 4px;
}

.inputs > * {
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/calendar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gDAAgD;EAChD,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".calendar-style {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 15px;\n}\n\n.calendar-style > .dotIcon {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n}\n\n.inputs {\n  z-index: 10;\n  background-color: #ffffff;\n  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px -1px;\n  padding: 10px;\n  position: absolute;\n  top: 30%;\n  right: 30%;\n  border-radius: 4px;\n}\n\n.inputs > * {\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
