import React, { useContext, useEffect, useState } from "react";
import EmpCard from "./EmpCard";
import axios from "axios";
import { toast } from "react-toastify";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Input, InputGroup } from "rsuite";
import NoDataFound from "./NoDataFound";
import Loading from "../Loader";
import { EssentialValues } from "../../App";
import CommonModel from "../Administration/CommonModel";
import { jwtDecode } from "jwt-decode";

const ManageTeam = () => {
    const [teamObj, setTeamObj] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [searchTeam, setSearchTeam] = useState('');
    const [dom, reload] = useState(false);
    const [assignEmp, setAssignEmp] = useState(false);
    const [addTeam, setAddTeam] = useState(false);
    const [employees, setEmployees] = useState([]); // Null indicates no team is being edited
    const [teams, setTeams] = useState([]);
    const [filteredTeams, setFilteredTeams] = useState([]);
    const [leads, setLeads] = useState([]);
    const [heads, setHeads] = useState([]);
    const [managers, setManagers] = useState([]);
    const url = process.env.REACT_APP_API_URL;
    const { data, whoIs } = useContext(EssentialValues);
    const { token, _id } = data;
    const [isChangingTeam, setIsChangingTeam] = useState(false);
    const { isTeamHead, isTeamLead, isTeamManager } = jwtDecode(token);

    const filterTeam = (e) => {
        setSearchTeam(e);
        if (e === "") {
            setFilteredTeams(teams);
        } else {
            setFilteredTeams(
                teams.filter((team) =>
                    team.teamName.toLowerCase().includes(e.toLowerCase())
                )
            );
        }
    };

    const toggleAddTeam = () => {
        setAddTeam(!addTeam);
        if (addTeam) {
            setTeamObj({});  // Reset editTeamObj when toggling out of add/edit mode
        }
    };

    const toggleAssignEmp = () => {
        setAssignEmp(!assignEmp);
    };

    const changeTeamObj = (value, name) => {
        console.log(value, name);

        setTeamObj((prev) => ({
            ...prev,
            [name]: value
        }));
    };


    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const res = await axios.get(`${url}/api/employee`, {
                    headers: {
                        Authorization: `${token}`
                    }
                });
                setEmployees(res.data.map((emp) => ({
                    label: emp.FirstName[0] + emp.FirstName.slice(1) + " " + emp.LastName,
                    value: emp._id
                })));

            } catch (err) {
                console.log(err);
            }
        };
        fetchEmployees();
    }, []);

    const reloadUI = () => {
        reload(!dom);
    };

    const deleteTeam = async (id) => {
        try {
            const res = await axios.delete(`${url}/api/team/${id}`, {
                headers: {
                    Authorization: `${token}` || ""
                }
            });

            toast.success(res.data.message);
            reloadUI();
        } catch (err) {
            toast.error(err.message);
        }
    };

    const editTeam = async (team) => {
        setIsChangingTeam(true);
        try {
            const res = await axios.get(`${url}/api/team/${team._id}`,
                {
                    headers: {
                        Authorization: `${token}` || ""
                    }
                }
            );

            setTeamObj(res.data);
            toggleAddTeam();
        } catch (err) {
            toast.error(err?.response?.data?.error);
        }
        setIsChangingTeam(false);
    };

    const handleSubmit = async () => {
        setIsChangingTeam(true);
        try {

            const response = await axios.post(`${url}/api/team`, teamObj, {
                headers: {
                    Authorization: `${token}` || ""
                }
            });

            toggleAssignEmp();
            toggleAddTeam();
            setTeamObj({})
            reloadUI();
            toast.success(response.data.message);
        } catch (err) {
            toast.error(err.response.data.error);
        }
        setIsChangingTeam(false)
    };

    const handleSubmitEdit = async () => {
        try {

            const res = await axios.put(`${url}/api/team/${teamObj._id}`, teamObj, {
                headers: {
                    Authorization: `${token}` || ""
                }
            });

            toggleAssignEmp();
            toggleAddTeam();
            reloadUI();
            toast.success(res.data.message);

        } catch (err) {
            console.log(err);

            toast.error(err.message);
        }
    };

    async function fetchLeads() {
        try {
            const res = await axios.get(`${url}/api/employee/team/lead`, {
                headers: {
                    Authorization: token || ""
                }
            })
            setLeads(res.data.map((emp) => ({
                label: emp.FirstName + " " + emp.LastName,
                value: emp._id
            })));
        } catch (error) {
            console.log(error);

            toast.error(error.repsonse.data.error)
        }
    }

    async function fetchHeads() {
        try {
            const res = await axios.get(`${url}/api/employee/team/head`, {
                headers: {
                    Authorization: token || ""
                }
            })

            setHeads(res.data.map((emp) => ({
                label: emp.FirstName + " " + emp.LastName,
                value: emp._id
            })));
        } catch (error) {
            toast.error(error.repsonse.data.error)
        }
    }

    async function fetchManagers() {
        try {
            const res = await axios.get(`${url}/api/employee/team/manager`, {
                headers: {
                    Authorization: token || ""
                }
            })
            setManagers(res.data.map((emp) => ({
                label: emp.FirstName + " " + emp.LastName,
                value: emp._id
            })));
        } catch (error) {
            toast.error(error.repsonse.data.error)
        }
    }

    async function fetchEmpHasTeams() {
        setIsLoading(true);
        try {
            const who = isTeamHead ? "head" : isTeamLead ? "lead" : "manager";
            const res = await axios.get(`${url}/api/team/${who}/${_id}`, {
                headers: {
                    Authorization: `${token}` || ""
                }
            });
            setTeams(res.data);
            setFilteredTeams(res.data);
        } catch (err) {
            console.log(err);

            toast.error(err.response.data.error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        const fetchTeams = async () => {
            setIsLoading(true);
            try {
                const res = await axios.get(`${url}/api/team`, {
                    headers: {
                        Authorization: `${token}` || ""
                    }
                });
                setTeams(res.data);
                setFilteredTeams(res.data);
            } catch (err) {
                console.log(err);

                toast.error(err.response.data.error);
            }
            setIsLoading(false);
        };
        if (["admin", "hr"].includes(whoIs)) {
            fetchTeams();
        } else if ([isTeamLead, isTeamHead, isTeamManager].includes(true)) {
            fetchEmpHasTeams()
        }

    }, [dom]);
    useEffect(() => {
        fetchHeads();
        fetchLeads();
        fetchManagers();
    }, [])

    return (
        isLoading ? <Loading height="80vh" /> :
            <div className="my-2">
                <div className="d-flex gap-2">
                    <InputGroup inside style={{ width: "300px" }}>
                        <Input placeholder="Team Name" className="m-0" value={searchTeam} onChange={filterTeam} />
                        <InputGroup.Button className="m-auto">
                            <SearchRoundedIcon />
                        </InputGroup.Button>
                    </InputGroup>
                    <button className="button" onClick={toggleAddTeam}>
                        {addTeam ? "Cancel" : "Add a new team"}
                    </button>
                </div>

                {addTeam && (
                    <CommonModel type="Team" leads={leads}
                        isAddData={addTeam}
                        changeData={changeTeamObj}
                        toggleAssignEmp={toggleAssignEmp}
                        editData={handleSubmitEdit}
                        heads={heads}
                        addData={handleSubmit}
                        dataObj={teamObj}
                        managers={managers}
                        modifyData={toggleAddTeam}
                        employees={employees}
                        isWorkingApi={isChangingTeam}
                    />
                )}

                {filteredTeams.length > 0 ? (
                    <div className="row d-flex justify-content-start">
                        {filteredTeams.map((team) => (
                            <EmpCard key={team._id} team={team} editTeam={editTeam} deleteTeam={deleteTeam} />
                        ))}
                    </div>
                ) : (
                    <NoDataFound message={"No teams found"} />
                )}
            </div>

    );
};

export default ManageTeam;
