// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commentsParent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.comments {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}
.commentsHeader {
  font-size: 2em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type="checkbox"] {
  width: 30px; /*Desired width*/
  height: 30px; /*Desired height*/
}

.timeBox {
  position: absolute;
  top: 0px;
  left: 15px;
  background-color: rgb(206, 229, 211);
  color: green;
  padding: 10px;
  font-weight: bold;
  border: 1px solid #a3dca3;
  border-radius: 2px;
}

.circleEditIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid gray;
}

.userProfile{
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 3px;
}

.imgsContainer{
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Comments.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,2CAA2C;EAC3C,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW,EAAE,gBAAgB;EAC7B,YAAY,EAAE,iBAAiB;AACjC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,oCAAoC;EACpC,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;EACE,SAAS;EACT,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".commentsParent {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n\n.comments {\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n  width: 100%;\n  height: 100%;\n  padding: 20px;\n  border-radius: 10px;\n  position: relative;\n}\n.commentsHeader {\n  font-size: 2em;\n  font-weight: 600;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\ninput[type=\"checkbox\"] {\n  width: 30px; /*Desired width*/\n  height: 30px; /*Desired height*/\n}\n\n.timeBox {\n  position: absolute;\n  top: 0px;\n  left: 15px;\n  background-color: rgb(206, 229, 211);\n  color: green;\n  padding: 10px;\n  font-weight: bold;\n  border: 1px solid #a3dca3;\n  border-radius: 2px;\n}\n\n.circleEditIcon {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  border: 1px solid gray;\n}\n\n.userProfile{\n    width: 30px;\n    height: 30px;\n    object-fit: cover;\n    border-radius: 50%;\n    margin-right: 3px;\n}\n\n.imgsContainer{\n  gap: 10px;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
